<template>
  <div class="container">
    <Header>
      <template v-slot:title>
        <span>{{ $t('bookingSuccess') }}</span>
      </template>
    </Header>
    <div class="main" style="height: 60%;">
      <div style="text-align: center; margin-top: 4em;">
        <van-icon name="passed" size="60" color="#005DA9" />
      </div>
      <div class="success-message">
        {{ $t('successMessage') }}
      </div>
    </div>

    <div class="footer">
      <van-button color="#005DA9" size="large" style="width: 100%;" @click="goMyBooking">{{ $t('bookingList') }}</van-button>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import { formatNumber } from '@/filters'
import { Notify } from 'vant'

export default {
  name: 'BookingSuccess',
  components: {
    Header
  },
  data () {
    return {

    }
  },
  computed: {
  },
  methods: {
    formatNumber,
    afterRead (file) {
      // 此时可以自行将文件上传至服务器
      console.log(file)
    },
    onOversize (file) {
      Notify(this.$t('file size limit is {limit} KB', { limit: 500 }))
    },
    goMyBooking () {
      this.$router.push({
        name: 'ReservationList'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  height: 100vh;
}

.main {
  padding-top: 64px;
  padding-bottom: 50px;
}

.success-message {
  text-align: center;
  font-size: 1.3rem;
  padding: 0 3rem;
  line-height: 1.4em;
}

.footer {
  margin: 0 1em;
}

</style>
